import { Injectable } from '@angular/core';
import { Profile } from '../../model/profile.model';

@Injectable({providedIn: 'root'})
export class DataLayerService {
    private readonly dataLayer: Array<object>;

    login(profile: Profile): void {
        if (!this.dataLayer) {
            return;
        }

        this.resetLayer();
        this.dataLayer.push({
            event: 'login',
            method: 'username',
            user: {
                username: profile.username,
                // @deprecated BAR-808
                member: profile.establishment,
                establishment: profile.establishment,
                contactFunction: profile.contactFunction,
                category: profile.category,
                fullname: profile.fullname,
                sector: profile.sectorName,
                isPro: profile.isPro,
                establishmentType: profile.establishmentType,
                department: profile.department,
                firstContactDate: profile.firstContactDate,
                commercial: profile.commercialName,
                networkCommercial: profile.networkCommercialName,
            },
        });
    }

    private resetLayer(): void {
        if (!this.dataLayer) {
            return;
        }
        // Never reset `user` custom index
        this.dataLayer.push({
            method: null,
            data: null,
            ecommerce: null,
        });
    }

    constructor() {
        // window.dataLayer depends on fourth argument in analytics component when building GTM script tag
        this.dataLayer = (window as any).dataLayer;
    }
}
