import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-panel',
    template: `
        <div class="rounded-3 {{ class }}" [ngClass]="{'bg-white shadow p-3' : withPanel}"><ng-content></ng-content></div>
    `,
})
export class PanelComponent {
    @Input() class = '';
    @Input() withPanel = true;
}
