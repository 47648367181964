import { Component, Inject, OnInit } from '@angular/core';
import { ENVIRONMENT } from '../../../../../common/environment/environment.config';
import { Environment } from '../../../../../common/environment/environment.model';

@Component({
    selector: 'app-tracking',
    template: '',
})
export class TrackingComponent implements OnInit {
    ngOnInit(): void {
        if ('development' !== this.environment.name && this.environment.leadInfo) {
            const tracking = document.createElement('script');
            tracking.type = 'text/javascript';
            /* eslint-disable max-len */
            tracking.innerText = '(function(l,e,a,d,i,n,f,o){if(!l[i]){l.GlobalLeadinfoNamespace=l.GlobalLeadinfoNamespace||[];';
            tracking.innerText += 'l.GlobalLeadinfoNamespace.push(i);';
            tracking.innerText += 'l[i]=function(){(l[i].q=l[i].q||[]).push(arguments)};';
            tracking.innerText += 'l[i].t=l[i].t||n;';
            tracking.innerText += 'l[i].q=l[i].q||[];';
            tracking.innerText += 'o=e.createElement(a);';
            tracking.innerText += 'f=e.getElementsByTagName(a)[0];';
            tracking.innerText += 'o.async=1;';
            tracking.innerText += 'o.src=d;';
            tracking.innerText += 'f.parentNode.insertBefore(o,f);}}';
            tracking.innerText += `(window,document,'script','https://cdn.leadinfo.net/ping.js','leadinfo','${this.environment.leadInfo}'));`;
            document.head.appendChild(tracking);
        }
    }

    constructor(
        @Inject(ENVIRONMENT) private environment: Environment,
    ) {}
}
