import { Component, Inject, OnInit } from '@angular/core';
import { ENVIRONMENT } from '../../environment/environment.config';
import { Environment } from '../../environment/environment.model';

@Component({
    selector: 'app-analytics',
    template: '',
})
export class AnalyticsComponent implements OnInit {
    ngOnInit(): void {
        if ('development' !== this.environment.name && this.environment.analytics) {
            const gtm = document.createElement('script');
            gtm.type = 'text/javascript';
            gtm.innerText = '(function(w,d,s,l,i){w[l]=w[l]||[];';
            gtm.innerText += 'w[l].push({\'gtm.start\': new Date().getTime(),event:\'gtm.js\'});';
            gtm.innerText += 'var f=d.getElementsByTagName(s)[0],';
            gtm.innerText += 'j=d.createElement(s),';
            gtm.innerText += 'dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';';
            gtm.innerText += 'j.async=true;j.src=\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;';
            gtm.innerText += 'f.parentNode.insertBefore(j,f);';
            gtm.innerText += `})(window,document,'script','dataLayer','${this.environment.analytics}');`;
            document.head.appendChild(gtm);
        }
    }

    constructor(
        @Inject(ENVIRONMENT) private environment: Environment,
    ) {}
}
