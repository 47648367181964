<h1>Changement du mot de passe</h1>
<ng-container *ngIf="!success">
    <ngb-alert *ngIf="tokenExpiredError" type="danger">
        <strong>Demande expirée</strong>.&nbsp;<a [routerLink]="['/reinitialiser-mot-de-passe']">Cliquez ici</a> pour faire une nouvelle demande.
    </ngb-alert>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="my-4">
        <div class="row mb-3">
            <div class="col">
                <label>Nouveau mot de passe</label>
                <input-password formControlName="password" [class.is-invalid]="$any(errors).plainPassword.password.length" [withPrepend]="true" />
                <div class="invalid-feedback"
                     *ngIf="$any(errors).plainPassword.password.length">{{ $any(errors).plainPassword.password[0] }}</div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <label>Confirmez le mot de passe</label>
                <input-password formControlName="confirmation"
                                [class.is-invalid]="$any(errors).plainPassword.confirmation.length"
                                [withPrepend]="true" />
                <div class="invalid-feedback"
                     *ngIf="$any(errors).plainPassword.confirmation.length">{{ $any(errors).plainPassword.confirmation[0] }}</div>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <a [routerLink]="['/']" class="btn btn-secondary">Retour</a>
            <button type="submit" class="btn btn-primary" [disabled]="loading">Envoyer</button>
        </div>
    </form>
</ng-container>
<ng-container *ngIf="success">
    <ngb-alert type="success">Votre mot de passe a été changé.</ngb-alert>
    <div class="d-flex">
        <button class="btn btn-primary mx-auto" [routerLink]="['/']">Se connecter</button>
    </div>
</ng-container>
