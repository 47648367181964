import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../../../common/services/auth.service';

@Component({
    templateUrl: './reset-password.template.html',
})
export class ResetPasswordComponent implements OnInit {
    form: UntypedFormGroup;
    loading = false;
    success = false;

    ngOnInit(): void {
        this.form = this.fb.group({
            username: ['', Validators.required],
        });
    }

    onSubmit(): void {
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.service.resetPassword(this.form.value.username)
            .pipe(finalize(() => this.loading = false))
            .subscribe(() => this.success = true);
    }

    constructor(
        private fb: UntypedFormBuilder,
        private service: AuthService,
    ) {}
}
