import { NgModule } from '@angular/core';
import { TrackingComponent } from './tracking.component';

@NgModule({
    declarations: [
        TrackingComponent,
    ],
    exports: [
        TrackingComponent,
    ],
})
export class TrackingModule {}
