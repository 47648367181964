import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '../../font-awesome/font-awesome.module';
import { NgbControlModule } from '../ngb-control/ngb-control.module';
import { InputPasswordComponent } from './input-password.component';

@NgModule({
    declarations: [InputPasswordComponent],
    exports: [InputPasswordComponent],
    imports: [
        CommonModule,
        FormsModule,

        FontAwesomeModule,
        NgbControlModule,
    ],
})
export class InputPasswordModule {}
