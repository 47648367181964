<h1>Mot de passe perdu ?</h1>
<ng-container *ngIf="!success; else passwordAskingSucceed">
    <p>Entrez votre identifiant (code établissement) ci-dessous pour créer un nouveau mot de passe.</p>
    <form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="onSubmit()" class="my-4">
        <div class="row mb-3">
            <div class="col">
                <label for="username" class="visually-hidden">Identifiant (code établissement)</label>
                <div class="input-group"
                     [class.is-invalid]="form.controls.username.invalid && (ngForm.submitted || form.controls.username.touched || form.controls.username.dirty)">
                    <span class="input-group-text bg-white"><i fa="regular" class="fa-sm fa-user"></i></span>
                    <input formControlName="username" type="text" class="form-control" id="username">
                </div>
                <div class="invalid-feedback">Champs obligatoire.</div>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <a [routerLink]="['/']" class="btn btn-secondary">Retour</a>
            <button type="submit" class="btn btn-primary" [disabled]="loading">Envoyer</button>
        </div>
    </form>
</ng-container>
<ng-template #passwordAskingSucceed>
    <ngb-alert type="success">
        Un message contenant la démarche à suivre vous a été envoyé à l'adresse liée à votre compte.
    </ngb-alert>
    <div>
        <a [routerLink]="['/']" class="btn btn-secondary me-auto">Retour</a>
    </div>
</ng-template>
