export class JwtHelper {
    static decode(token: string): any {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+')
            .replace(/_/g, '/');

        return JSON.parse(window.atob(base64));
    }

    static getTokenExpirationDate(token: string): Date | null {
        const decoded = JwtHelper.decode(token);

        if (!decoded.hasOwnProperty('exp')) {
            return null;
        }

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);

        return date;
    }

    static isExpired(token: string): boolean {
        if (null === token || '' === token) {
            return true;
        }

        const date = JwtHelper.getTokenExpirationDate(token);

        return null === date || date.valueOf() <= new Date().valueOf();
    }
}
