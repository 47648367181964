import { Directive, ElementRef, HostBinding, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[ngbControl]',
})
export class NgbControlDirective implements OnInit {
    @HostBinding('class.form-check-input') formCheckInput = false;
    @HostBinding('class.form-control') formControlClass = true;
    @Input() label: string = null;
    @Input() ngbControl = '';

    ngOnInit(): void {
        if (
            !this.isCustom && (
                'checkbox' === this.elem.nativeElement.getAttribute('type') ||
                'radio' === this.elem.nativeElement.getAttribute('type')
            )
        ) {
            this.formControlClass = false;
            this.formCheckInput = true;
        }

        if (this.readonly) {
            if (this.formControlClass && !this.isCustom) {
                this.elem.nativeElement.classList.add('form-control-plaintext');
            }

            this.elem.nativeElement.setAttribute('readonly', 'readonly');
            this.elem.nativeElement.setAttribute('disabled', 'disabled');
            if ('TEXTAREA' === this.elem.nativeElement.nodeName) {
                this.elem.nativeElement.style.resize = 'none';
            }
        }

        if (this.isCustom) {
            this.formControlClass = false;
            this.formCheckInput = true;
        }
    }

    get readonly(): boolean {
        return this.ngbControl.toLowerCase().includes('readonly');
    }

    get isCustom(): boolean {
        return this.ngbControl.toLowerCase().includes('custom');
    }

    constructor(public elem: ElementRef) {}
}
