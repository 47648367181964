import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FontAwesomeStyle } from './font-awesome.type';

@Directive({
    selector: '[fa]',
})
export class FontAwesomeDirective implements OnInit, OnChanges {
    @Input() fa: FontAwesomeStyle;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.fa && changes.fa.previousValue && changes.fa.currentValue) {
            this.element.nativeElement.classList.remove(...changes.fa.previousValue.split(' ').map(style => `fa-${style}`));
            this.element.nativeElement.classList.add(...changes.fa.currentValue.split(' ').map(style => `fa-${style}`));
        }
    }

    ngOnInit() {
        this.element.nativeElement.classList.add(...this.fa.split(' ').map(style => `fa-${style}`));
    }

    constructor(
        private element: ElementRef,
    ) {
    }
}
