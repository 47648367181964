import merge from 'ts-deepmerge';

export class SpreadRecursive {
    static clone(...sources: Array<object>): any {
        return SpreadRecursive.merge({}, ...sources);
    }

    static cloneArray(sources: Array<object>): any {
        return sources.map(source => SpreadRecursive.clone(source));
    }

    static merge(target: object, ...sources: Array<object>): any {
        return merge(target, sources[0]);
    }
}
