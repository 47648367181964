<div class="d-flex mb-4">
    <img ngSrc="client-assets/baresto-logo.jpg" alt="Logo Baresto" class="mx-auto" width="250" height="128">
</div>

<ngb-alert *ngIf="errorMessage" type="danger">
    {{ errorMessage }}
</ngb-alert>
<form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="onSubmit()" class="my-4">
    <div class="row mb-3">
        <div class="col">
            <label for="username">Identifiant</label>
            <div class="input-group"
                 [class.is-invalid]="form.controls.username.invalid && (ngForm.submitted || form.controls.username.touched || form.controls.username.dirty)">
                <span class="input-group-text bg-white{{ form.controls.username.invalid && (ngForm.submitted || form.controls.username.touched || form.controls.username.dirty) ? ' text-danger border-danger' : null }}"><i fa="regular" class="fa-sm fa-user"></i></span>
                <input type="text" autofocus class="form-control" id="username"
                       formControlName="username"
                       [class.is-invalid]="form.controls.username.invalid && (ngForm.submitted || form.controls.username.touched || form.controls.username.dirty)">
            </div>
            <div class="invalid-feedback">Champs obligatoire.</div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col">
            <label>Mot de passe</label>
            <input-password [class.is-invalid]="form.controls.password.invalid && (ngForm.submitted || form.controls.password.touched || form.controls.password.dirty)"
                            formControlName="password" [withPrepend]="true" />
            <div class="invalid-feedback">Champs obligatoire.</div>
        </div>
    </div>
    <div class="d-flex">
        <button type="submit" class="btn btn-primary mx-auto" [disabled]="form.invalid || loading">S'identifier</button>
    </div>
</form>
<hr>
<div class="mt-4 d-block">
    <h3>Mot de passe perdu ?</h3>
    <p>En cas de besoin, pour obtenir votre identifiant contactez votre interlocuteur Baresto.</p>
    <p><a [routerLink]="['/reinitialiser-mot-de-passe']">Cliquez ici</a>&nbsp;pour en créer un nouveau.</p>
</div>
