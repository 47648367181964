import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EMPTY } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { DataLayerService } from '../../../../../common/modules/analytics/data-layer.service';
import { AuthService } from '../../../../../common/services/auth.service';
import { SessionService } from '../../../../../common/services/session.service';

@Component({
    templateUrl: './login.template.html',
})
export class LoginComponent implements OnInit {
    errorMessage = '';
    form: UntypedFormGroup;
    loading = false;

    ngOnInit(): void {
        this.form = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    onSubmit(): void {
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.auth.login({...this.form.value})
            .pipe(take(1))
            .pipe(catchError((error: HttpErrorResponse) => {
                this.loading = false;
                this.errorMessage = error.error.message;

                if ('Invalid credentials.' === error.error.message) {
                    this.errorMessage = 'Identifiants invalides.';
                } else if ('Too many failed login attempts, please try again in 15 minutes.' === error.error.message) {
                    this.errorMessage = 'Plusieurs tentatives de connexion ont échoué, veuillez réessayer dans 15 minutes.';
                }

                return EMPTY;
            }))
            .subscribe(() => {
                this.dataLayer.login(this.session.getUser().profile);
                this.auth.redirectToApp();
            });
    }

    constructor(
        private fb: UntypedFormBuilder,
        private auth: AuthService,
        private dataLayer: DataLayerService,
        private session: SessionService,
    ) {}
}
