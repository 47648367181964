import { NgModule } from '@angular/core';
import { NgbAlertConfig, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertDirective } from './alert.directive';

@NgModule({
    declarations: [
        AlertDirective,
    ],
    imports: [
        NgbAlertModule,
    ],
    exports: [
        NgbAlertModule,

        AlertDirective,
    ],
})
export class AlertModule {
    constructor(alertConfig: NgbAlertConfig) {
        alertConfig.type = 'success';
        alertConfig.dismissible = false;
    }
}
