import { NgModule } from '@angular/core';
import { AnalyticsComponent } from './analytics.component';

@NgModule({
    declarations: [
        AnalyticsComponent,
    ],
    exports: [
        AnalyticsComponent,
    ],
})
export class AnalyticsModule {}
