import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { PasswordChange } from '../model/auth/password-change.model';
import { Credentials } from '../model/credentials.model';
import { HttpParams } from '../model/http/http-params';
import { Profile } from '../model/profile.model';
import { SessionService } from './session.service';

@Injectable({providedIn: 'root'})
export class AuthService {
    changePassword(passwordChange: PasswordChange): Observable<any> {
        return this.http.post(`/api/change-password?token=${passwordChange.token}`, {
            plainPassword: {
                password: passwordChange.password,
                confirmation: passwordChange.confirmation,
            },
        }).pipe(take(1));
    }

    checkAuth(): Observable<any> {
        return this.http.head('/api/ping').pipe(take(1));
    }

    login(user: Credentials): Observable<any> {
        return this.http.post('/api/login_check', {
            username: user.username,
            password: user.password,
        })
            .pipe(tap((response: any) => this.session.storeUser({...response})));
    }

    redirectToApp(): void {
        if (this.session.isValid()) {
            const user = this.session.getUser();
            let redirectTo = localStorage.getItem('redirectTo');
            localStorage.removeItem('redirectTo');

            if (user.profile.isBackend) {
                if (redirectTo && !redirectTo.startsWith('/backend') && !redirectTo.startsWith('/admin')) {
                    redirectTo = null;
                }
                window.location.href = redirectTo || '/backend';
            }

            if (user.profile.isFrontend) {
                if (redirectTo && !redirectTo.startsWith('/portail')) {
                    redirectTo = null;
                }
                window.location.href = redirectTo || '/portail';
            }
        }
    }

    resetPassword(username: string): Observable<any> {
        return this.http.post('/api/reset-password', {username}).pipe(take(1));
    }

    resetUserSwitched(): void {
        this.session.resetUserSwitched();
        this.redirectToApp();
    }

    switchUser(username: string): void {
        this.http.get('/api/portal/account/user/profile', {params: new HttpParams({_switch_user: username}).getParams()})
            .pipe(take(1))
            .subscribe((profile: Profile) => {
                this.session.switchUser(profile);
                this.redirectToApp();
            });
    }

    constructor(
        private http: HttpClient,
        private session: SessionService,
    ) {}
}
