import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { PasswordChange } from '../../../../../common/model/auth/password-change.model';
import { AbstractFormBuilderComponent } from '../../../../../common/modules/form/abstract-form-builder.component';
import { FormErrors } from '../../../../../common/modules/form/model/form-errors.model';
import { AuthService } from '../../../../../common/services/auth.service';
import { SpreadRecursive } from '../../../../../common/services/spread-recursive';

@Component({
    templateUrl: './change-password.template.html',
})
export class ChangePasswordComponent extends AbstractFormBuilderComponent implements OnInit {
    errors: FormErrors;
    loading = false;
    success = false;
    token: string = null;
    tokenExpiredError = false;

    ngOnInit(): void {
        super.ngOnInit();
        this.resetErrors();
        this.token = this.route.snapshot.queryParams.token;
    }

    onSubmit(): void {
        this.loading = true;
        const passwordChange: PasswordChange = {
            password: this.form.value.password,
            confirmation: this.form.value.confirmation,
            token: this.token,
        };
        this.service.changePassword(passwordChange)
            .pipe(finalize(() => this.loading = false))
            .pipe(catchError((response: HttpErrorResponse) => {
                this.resetErrors();
                if (response.error?.errors) {
                    this.errors = SpreadRecursive.merge(this.errors, response.error.errors);
                } else {
                    this.tokenExpiredError = true;
                }

                return new Observable(undefined);
            }))
            .subscribe(() => this.success = true);
    }

    protected createForm(): object {
        return {
            password: null,
            confirmation: null,
        };
    }

    private resetErrors(): void {
        this.tokenExpiredError = false;
        this.errors = {
            plainPassword: {
                password: [],
                confirmation: [],
            },
        };
    }

    constructor(
        fb: UntypedFormBuilder,
        private service: AuthService,
        private route: ActivatedRoute,
    ) {
        super(fb);
    }
}
