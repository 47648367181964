import { Component, ElementRef, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractValueAccessorComponent } from '../abstract-value-accessor.component';

@Component({
    selector: 'input-password',
    template: `<div class="input-group">
        <span class="input-group-text bg-white{{ isInvalid ? ' text-danger border-danger' : null }}">
            <i fa="regular" class="fa-sm fa-lock"></i>
        </span>
        <input [type]="inputType" ngbControl [class.is-invalid]="isInvalid"
               [(ngModel)]="value" (change)="onChange($event.target.value)">
        <button type="button" class="btn" (click)="toggleVisibility()"
                [ngClass]="{'btn-outline-secondary': !isInvalid || true, 'btn-outline-danger': isInvalid}">
            <i fa="solid" [ngClass]="{
                  'fa-eye': 'password' === inputType,
                  'fa-eye-slash': 'text' === inputType}"></i>
        </button>
    </div>`,
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => InputPasswordComponent),
        multi: true,
    }],
})
export class InputPasswordComponent extends AbstractValueAccessorComponent {
    @Input() withPrepend = false;

    inputType = 'password';
    value: string;

    toggleVisibility(): void {
        if ('password' === this.inputType) {
            this.inputType = 'text';
        } else {
            this.inputType = 'password';
        }
    }

    writeValue(obj: string): void {
        this.value = obj;
    }

    get isInvalid(): boolean {
        return this.element.nativeElement.classList.contains('is-invalid');
    }

    constructor(
        private element: ElementRef,
    ) {
        super();
    }
}
